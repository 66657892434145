import React from "react"
import FeedbackForm from "../components/headerAndFooter/FeedbackForm"
import Layout from "../components/utils/layout"
import SEO from "../components/utils/seo"
import dots from "../images/bgs/dots.svg"


const Support = () => (
  <Layout image={dots}>
    <SEO title="Support" />
    <FeedbackForm />
  </Layout>
)

export default Support
